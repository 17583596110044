<template>
    <div class="content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <div class="rq-container">
            <div class="row">
                <div :class="{ 'col col-3 form-group':true, 'form-required': isDateRequired, 'has-error': v$.item.depositDate.$error }">
                    <label for="dtp_deposit_date">Date</label>
                    <div v-rq-tooltip.bottom.hover :title="disableDepositDateTooltip">
                        <rqdx-date-box
                            id="dtp_deposit_date"
                            ref="dtp_deposit_date"
                            :disabled="readOnly || hasReconciliationDate || disableDepositDate"
                            :disabled-dates="getDisabledDepositDates"
                            @valueChanged="onDepositDateChanged"
                            v-model="v$.item.depositDate.$model"
                        />
                        <rq-validation-feedback
                            :messages="{
                                'Date is required when Fund Type is selected': v$.item.depositDate.isValid.$invalid,
                                'Date is not within range': v$.item.depositDate.isInRange.$invalid,
                                }"
                        />
                    </div>
                </div>
                <div :class="{ 'col col-3 form-group form-required':true, 'has-error':v$.item.amount.$error }">
                    <label for="txt_amount">Amount</label>
                    <rq-input-number
                        automation_id="txt_amount"
                        formatType="money"
                        defaultValue="0"
                        :disabled="readOnly || hasReconciliationDate || !isNew"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="v$.item.amount.$model"
                        />
                        <rq-validation-feedback
                            :messages="{ 'Amount is required': v$.item.amount.required.$invalid,
                                         'Invalid Amount': v$.item.amount.isInRange.$invalid,
                                        }"
                        />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_wire_number">Wire Number</label>
                    <input
                        automation_id="txt_wire_number"
                        v-model="item.wireNumber"
                        :disabled="readOnly || hasReconciliationDate"
                        @change="onWireNumberChanged"
                        type="text"
                        class="form-control"
                        placeholder="Wire Number"
                        maxlength="15">
                </div>
                <div :class="{ 'col col-3 form-group':true, 'form-required': isTypeFundRequired, 'has-error':v$.item.typeFundID.$error }">
                    <label for="cmb_type_fund_id">Type of Funds</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_typeFundID', id: 'drp_typeFundID' }"
                        :items="excludedFundTypeList"
                        :disabled="readOnly || hasReconciliationDate || hasWireNumber || hasExpectedWireDate || isFundTypeExcluded"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.item.typeFundID.$model"
                        :search-enabled="true"
                        @valueChanged="onFundTypeChange"
                        @focusOut="onFundTypeBlur"
                    />
                    <rq-validation-feedback
                        :messages="{
                            'Type of Funds is required': v$.item.typeFundID.required.$invalid,
                            'Booked Receipts must have a Fund Type': v$.item.typeFundID.isValid.$invalid
                            }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 form-group">
                    <label class="form-control-label" for="txt_payor">Payor</label>
                    <input automation_id="txt_payor"
                        class="form-control"
                        type="text"
                        v-model="item.payor"
                        :disabled="readOnly || hasReconciliationDate"
                        maxlength="200"
                    >
                </div>
                <div class="col col-6 form-group" v-if="isNew">
                    <label for="drp_accountingCodeID">Accounting Code</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_accountingCodeID', id: 'drp_accountingCodeID' }"
                        :items="accountingCodes"
                        :disabled="readOnly || hasReconciliationDate"
                        value-expr="id"
                        display-expr="name"
                        v-model="item.accountingCodeID"
                        :search-enabled="true"
                    />
                </div>
                <div class="col col-3 form-group" v-if="!isNew">
                    <label for="txt_verified_date">Verified Date</label>
                    <input
                        automation_id="txt_verified_date"
                        class="form-control"
                        type="text"
                        readOnly
                        :value="formatDate(item.depositVerified)"
                    >
                </div>
                <div class="col col-3 form-group" v-if="!isNew">
                    <label for="txt_reconciliation_date">Recon Date</label>
                    <input
                        automation_id="txt_reconciliation_date"
                        class="form-control"
                        type="text"
                        readOnly
                        :value="formatDate(item.reconciliationDate)"
                    >
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-6 form-group" v-if="isNew">
                    <label class="form-control-label" for="txt_description">Description</label>
                    <input automation_id="txt_description"
                        class="form-control"
                        type="text"
                        :disabled="readOnly || hasReconciliationDate"
                        v-model="item.description"
                        maxlength="60"
                    >
                </div>
                <div class="col col-3 form-group" v-if="showExpectedWireOutDates">
                    <label for="txt_expected_wire_date">Expected Wire Date</label>
                    <rqdx-date-box
                        id="dtp_expected_wire_date"
                        ref="dtp_expected_wire_date"
                        :disabled="readOnly || disableExpectedWireDate"
                        @valueChanged="onExpectedWireDateChanged"
                        v-model="item.expectedWireDate"
                    />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_fed_reference_number">Fed Reference No.</label>
                    <input
                        automation_id="txt_fed_reference_number"
                        class="form-control"
                        type="text"
                        maxlength="25"
                        :disabled="readOnly"
                        v-model="item.fedRefNumber"
                        />
                </div>                  
                <div :class="showExpectedWireOutDates ? 'col col-3 form-group' : 'col col-6 form-group'">
                    <label for="txt_reference_number">Reference Number</label>
                    <input
                        automation_id="txt_reference_number"
                        class="form-control"
                        type="text"
                        maxlength="50"
                        :disabled="readOnly || hasReconciliationDate"
                        v-model="item.referenceNumber"
                    >
                </div>
            </div>
            <rqdx-action-data-grid
                v-if="!isNew"
                ref="dataGrid"
                title-size="md"
                automation_id="tbl_deposit_lines"
                class="rq-cw-modal-datagrid"
                :actions="selectionActions"
                :config="gridConfig"
                :data-source="gridDataSource"
                @delete="onDeleteDetailItem"
                :read-only="readOnly"
                :persist-state="false"
                hide-search
                hide-show-column-chooser
                rq-editable
                rq-filters>
                <template #toolbar>
                    <ul class="nav me-auto">
                        <li class="nav-item" v-rq-tooltip.hover.top="{ title: addCheckLineToolip }">
                            <b-button
                                automation_id="btn_add_deposit_line"
                                class="btn btn-theme me-1"
                                variant="theme"
                                @click="onAddLine"
                                :disabled="readOnly || !showAddLine || lineLimitMet"
                                >Add Line
                            </b-button>
                        </li>
                    </ul>
                </template>
            </rqdx-action-data-grid>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import { DepositShortDto, DepositLineDto }  from "../models";
    import { DepositStatus, FundOption, CreatedFromSource } from '../enums';
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { DepositEditingOptions } from '../../../configuration/enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    const FINALIZED_RECON_WARNING = "Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.";

    export default {
        name: 'AddEditDepositForm',
        mixins: [GridSystemLookupMixin],
        props: {
            deposit: {type: Object, required: true},
            depositLines: {type: Array, required: true},
            depositLineLookups: { type: Array, required: true, default: () => [] },
            bank: {type: Object, required: true},
            summary: { type: Object, required: true, default: () => ({}) },
        },
        components: {  },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                errorMessage: "",
                originalItem: {},
                originalDetail: [],
                item: {},
                itemDetail: [],
                fundTypes: [],
                ledgerSummary: {},
                detailUpdates: false,
                refreshOrder: false
            }
        },
        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user
            }),
            disableDepositDate() {
                 return !this.isNew && !_.isNil(this.item.depositDate) &&
                    !this.localSecurity.AllowFinalizedReconModifications && !this.depositDateChanged &&
                    DateTime.fromISO(this.item.depositDate).diff(DateTime.fromISO(this.order.lastFinalizedReconDate), "days").days <= 0;
            },
            disableDepositDateTooltip() { return this.disableDepositDate ? "Access Restricted" : ""; },
            disableExpectedWireDate() { return !_.includes([0, 7], this.item.typeFundID); },
            hasReconciliationDate() { return !_.isNil(this.item.reconciliationDate); },
            hasExpectedWireDate() { return !_.isNullOrEmpty(this.item.expectedWireDate); },
            hasWireNumber() { return !_.isNullOrEmpty(this.item.wireNumber); },
            isCdf() { return _.isEqual(_.get(this, "summary.autoRecreateSource", -1), 3); },
            isDateRequired() { return _.getNumber(this, 'item.typeFundID', 0) > FundOption.None && _.getNumber(this, 'item.typeFundID', 0) !== FundOption.Wire; },
            dateRangeCheckRequired() { return _.getNumber(this, 'item.typeFundID', 0) > FundOption.None; },
            isTypeFundRequired() { return !_.isNullOrEmpty(this.item.depositDate); },
            isNew() { return _.getNumber(this, 'item.depositID', 0) == 0; },
            isWire() { return _.getNumber(this, 'item.typeFundID', 0) == FundOption.Wire; },
            isFundTypeExcluded() { var excludedIDs = [8,9,10,11];
            return !this.isNew && (_.includes(excludedIDs, (_.getNumber(this,'item.typeFundID',0))))},
            excludedFundTypeList() { if(this.isNew || !this.isFundTypeExcluded){return _.filter(this.fundTypes, ft => { return (ft.id < 8  || ft.id > 11); });} else {return this.fundTypes}},
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "AllowFinalizedReconModifications",
                    "AllowDepositDelete",
                    "AllowWires",
                    "AllowEscrowAdminBrowserChange",
                    "DepositChangeOption",
                    "DepositPreDateDays",
                    "DepositPostDateDays",
                    "DepositSlip_ScreenAccess",
                    "IncludeAdjustmentTypeFunds"
                    ]);
            },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            readOnly() {
                if (this.localSecurity.DepositChangeOption === DepositEditingOptions.NoChange && _.get(this.deposit, 'depositID', 0) > 0) {
                    return true;
                }
                if (this.localSecurity.DepositChangeOption === DepositEditingOptions.ChangeNonBooked && _.get(this.item, 'depositStatus', 0) != DepositStatus.Anticipated) {
                    return true;
                }
                if (this.localSecurity.DepositChangeOption === DepositEditingOptions.ChangeUnverifiedUnreconciled && (_.get(this.item, 'depositVerified', null) != null || _.get(this.item, 'reconciliationDate', null) != null)) {
                    return true;
                }
                return false;
            },
            showAddLine() { return _.get(this.item, "typeFundID", 0) != FundOption.Transfer; },
            lineLimitMet() {
                return  this.bank.depositSlipLineLimit > 0 ? (this.itemDetail.length >= this.bank.depositSlipLineLimit) : false;},
            showExpectedWireOutDates() { return _.parseBool(_.get(this, 'systemDefaults.showExpectedWireDates', false), false) },
            warnFinalRecon() {
                return (!this.isNew && !_.isNil(this.originalItem.depositDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.originalItem.depositDate).diff(DateTime.fromISO(this.order.lastFinalizedReconDate), "days").days <= 0)
                    || (this.isNew && !_.isNil(this.item.depositDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.item.depositDate).diff(DateTime.fromISO(this.order.lastFinalizedReconDate), "days").days <= 0);
            },
            addCheckLineToolip() { return !this.lineLimitMet ? `Add Receipt Line` : `Max Receipt Line # of ${this.bank.depositSlipLineLimit} reached.`; },
            depositDateChanged() { return this.originalItem.depositDate !== this.item.depositDate;}
        },
        created(){
            this.init();
            this.initNonReactiveVariables();
            this.initGridConfig();
        },
        mounted() {
            _.invoke(this, `$refs.dtp_deposit_date.focus`);
        },
        validations() {
            const self = this;

            var result = {
                item: {
                    depositDate: {
                        isValid(val) {
                            return !(self.isDateRequired && _.isNullOrEmpty(val));
                        },
                        isInRange(val) {
                            return  !(this.dateRangeCheckRequired && self.isDepositDateInRange(val));
                        }
                    },
                    typeFundID: {
                        required,
                        isValid(val) {
                            return !(!_.isNullOrEmpty(self.item.depositDate) && val == FundOption.None);
                        }
                    },
                    amount: {
                        required,
                        isInRange(val) {
                            return !_.isEqual(val, 0);
                        }
                    },
                }
            }
            return result;
        },
        methods: {
            allowEditAccountCodePermission(rowData) {
                //RQO-19038 - if reconciled, and a wire, do not allow editing
                if (_.getNumber(this.item, "depositStatus", null) === DepositStatus.Reconciled && _.getNumber(this.item, "typeFundID", 0) === FundOption.Wire) return false;
                //RQO-16972 - If a User Edits their own Manual Check, they can edit account Code
                if (_.isEqual(this.user.fullName, this.item.usersDisplayName) && _.isEqual(_.get(rowData, "createSource"), CreatedFromSource.Manual)) return true;
                //RQO-16972 - If a User Edits any check line without an Account Code, they can set it
                if (_.getNumber(rowData, "accountingCodeID", 0) === 0) return true;
                //Otherwise, use system setting
                return this.systemDefaults.onlySystemAdminsCanEditAccountingCodes ? this.isSysAdmin : true;
            },

            formatDate(v) { return DateTime.fromISO(v).isValid ? DateTime.fromISO(v).toFormat("MM/dd/yyyy") : ""; },

            getDisabledDepositDates(e) {
                //note: returning true disables the date, false enables it.  Can be confusing.
                let now = DateTime.now().startOf("day");
                let reconDate = DateTime.fromISO(this.order.lastFinalizedReconDate).plus({days: 1}).startOf("day");

                // RQO-31002 - set the reconDate to today if no recon done yet
                if (reconDate.year === 1900) {
                    reconDate = now.minus({days: Math.abs(this.localSecurity.DepositPreDateDays)}, 'days');
                }

                let reconDaysDiff = reconDate.diff(now, "days").days;
                let allowFinalizedReconModifications = _.parseBool(this.localSecurity.AllowFinalizedReconModifications, false);
                //if someone is not allowed to alter finalized recons, then they should not be allowed to add a deposit that falls within the range of that recon.
                let depositPreDateDaysSetting = allowFinalizedReconModifications ? this.localSecurity.DepositPreDateDays : Math.abs(reconDaysDiff);
                let depositPostDateDaysSetting = this.localSecurity.DepositPostDateDays;

                //-1 === unlimited
                if(depositPreDateDaysSetting === -1 && depositPostDateDaysSetting === -1) return false;

                let currentDate = DateTime.fromJSDate(e.date).startOf("day").isValid ? DateTime.fromJSDate(e.date).startOf("day") : DateTime.fromISO(e.date).startOf("day");
                if(!allowFinalizedReconModifications && reconDate > currentDate) return true;

                let daysDiff = currentDate.diff(now, "days").days;

                if(daysDiff === 0) return false; //today is always valid

                //predate is unlimited but not postdate
                if(depositPreDateDaysSetting === -1){
                    if(daysDiff <= 0) return false;
                    return daysDiff > depositPostDateDaysSetting;
                }
                //postdate is unlimited but not predate
                else if(depositPostDateDaysSetting === -1){
                    if(daysDiff >= 0) return false;
                    return Math.abs(daysDiff) > depositPreDateDaysSetting;
                }
                //neither is unlimited
                return (daysDiff < 0 && Math.abs(daysDiff) > depositPreDateDaysSetting) || (daysDiff > 0 && daysDiff > depositPostDateDaysSetting);
            },

            getNextDetailLine() {
                let sequence = _.get(_.maxBy(this.itemDetail, "sequence"), "sequence", 0) + 1;
                let depositID = this.item.depositID;
                let createSource = CreatedFromSource.Manual;
                return {
                    sequence: sequence,
                    lineNumberStr: "",
                    depositID : depositID,
                    createSource: createSource
                };
            },

            gridHasChanges(){
                const self = this;
                if(!_.isEmpty(self.gridInstance) && self.gridInstance.hasEditData()) return true;
                return false;
            },

            getDepositLineLookup(lineStr) {
                if (_.isNullOrEmpty(lineStr)) return;
                return _.find(this.depositLineLookups, ["lineNumber", lineStr.toUpperCase()]);
            },

            isDepositDateInRange(val){
                const self = this;
                if (_.isNil(val)) return false;
                return self.getDisabledDepositDates({date: val});
            },

            isUniqueLine(row){
                const self = this;
                if (_.isNullOrEmpty(row.lineNumber) && _.isNullOrEmpty(row.lineNumberStr)) return true;//only validate if the line number exists
                let dup = {};
                dup = _.find(self.itemDetail, (i) => {
                    if (self.isCdf) {
                        return  _.isEqual(_.trim(i.lineNumberStr), _.trim(row.lineNumberStr))
                                && _.parseNumber(_.get(i, self.itemKey, -1), -1) == _.parseNumber(_.get(row, self.itemKey, -1), -1)
                                && _.parseNumber(_.get(i, self.detailKey, -1), -1) != _.parseNumber(_.get(row, self.detailKey, -1), -1);
                    } else {
                        return  _.parseNumber(i.lineNumber, -1) === _.parseNumber(row.lineNumber, -1)
                                && _.parseNumber(_.get(i, self.itemKey, -1), -1) == _.parseNumber(_.get(row, self.itemKey, -1), -1)
                                && _.parseNumber(_.get(i, self.detailKey, -1), -1) != _.parseNumber(_.get(row, self.detailKey, -1), -1);
                    }
                });
                return _.isEmpty(dup);
            },

            init() {
                this.ledgerSummary = _.clone(this.orderSummary);
                this.originalItem = _.clone(this.deposit);
                this.originalDetail = _.clone(this.depositLines);
                this.item = _.clone(this.deposit);
                this.itemDetail = _.clone(this.depositLines);
                this.fundTypes = this.lookupHelpers.getLookupItems(this.lookupItems.TYPE_FUNDS);
                if (!this.localSecurity.AllowWires) {
                    let wireOption = _.find(this.fundTypes, ["id", FundOption.Wire]);
                    if (wireOption) wireOption.disabled = true;
                }
                if (!this.localSecurity.IncludeAdjustmentTypeFunds) {
                    let adj = _.find(this.fundTypes, ["id", FundOption.Adjustment]);
                    if (adj) adj.disabled = true;
                }
                this.$emit(`${this.readOnly ? "disable" : "enable"}-ok`);
            },

            initGridConfig(){
                const self = this;
                let validateLineNumber = (item) => {
                    if (this.isCdf) {
                        let value = item.data.lineNumberStr;
                        let isAlphaNumeric = !!value?.match(/([A-Za-z][0-9]+)/gm);
                        if (isAlphaNumeric) return true;
                        return _.gte(_.parseNumber(item.data.lineNumberStr, 0), 0);
                    }
                    return _.gte(_.parseNumber(item.data.lineNumber, 0), 0);
                }

                self.gridConfig = {
                    allowColumnReordering: false,
                    onEditorPreparing: self.onEditorPreparing,
                    onEditingStart: self.onEditingStart,
                    focusedRowEnabled: false,
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    scrolling: { useNative: true },
                    columns: [
                        {
                            dataField: "lineNumberStr",
                            dataType: "string",
                            caption: "Line",
                            editCellTemplate: DxGridUtils.generateCustomEditor({
                                tooltipMessage: "Line Number Created from Settlement Statement is Unavailable for Edit.",
                                maxLength: 3
                            }),
                            setCellValue: function(rowData, value) {
                                let newValue = _.isNullOrEmpty(value) ? "" : value.toString().trim().toUpperCase();
                                rowData.lineNumber = newValue; 
                                rowData.lineNumberStr = newValue; 
                                let lookupInfo = self.getDepositLineLookup(newValue);
                                if (lookupInfo) {
                                    let lineAdd = _.parseNumber(newValue.substring(1));
                                    rowData.lineNumber = (lookupInfo.seedValue + lineAdd) * -1;
                                    rowData.description = lookupInfo.lineDescription;
                                    rowData.amount = lookupInfo.amount;
                                }
                            },
                            calculateCellValue: function(rowData){
                                return DxGridUtils.lineNumberStrDisplay(rowData)
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    message: "Invalid Line Number.",
                                    validationCallback: item => !self.isCdf ? true : validateLineNumber(item)
                                },
                            ],
                            width: 100,
                            showInColumnChooser: self.isCdf,
                            visible: self.isCdf
                        },
                        {
                            dataField: "lineNumber",
                            dataType: "number",
                            caption: "Line",
                            editorOptions: { maxLength: 4 },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTextBox({
                                    value: cellInfo.value,
                                    showClearButton: false,
                                    valueChangeEvent: "change",
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(rowData, value) {
                                let newValue = _.isNullOrEmpty(value) ? "" : value.toString().trim().toUpperCase();
                                rowData.lineNumber = newValue;
                                let lookupInfo = self.getDepositLineLookup(newValue);
                                if (lookupInfo) {
                                    rowData.lineNumberStr = lookupInfo.lineNumber;
                                    rowData.description = lookupInfo.lineDescription;
                                    rowData.amount = lookupInfo.amount;
                                }
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    message: "Invalid Line Number.",
                                    validationCallback: item => self.isCdf ? true : validateLineNumber(item)
                                }
                            ],
                            width: 100,
                            showInColumnChooser: !self.isCdf,
                            visible: !self.isCdf
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            editorOptions: { maxLength: 60 }
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    message: "Total sum must be greater than 0.",
                                    validationCallback: item => self.isAmountGreaterThanZero([item.data])
                                }
                            ],
                            editorOptions: { format: {type: "currency", precision: 2}, showClearButton: true },
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            allowEdit:self.allowEditAccountCodePermission,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalLabel",
                                column: "description",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "Total",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                        ]
                    },
                    onInitNewRow(e) {
                        e.data = self.getNextDetailLine();

                        self.$nextTick().then(() => {
                        if(self.lineLimitMet){
                            self.gridInstance.cancelEditData();
                        }
                        });
                    },
                };

                self.gridDataSource = {
                    key: self.detailKey,
                    load (loadOptions) {
                        return Promise.resolve(self.itemDetail);
                    },
                    insert: self.onDetailGridInsert,
                    update: self.onDetailGridUpdate
                };
            },

            isAmountGreaterThanZero(items) {
                const self = this;
                let itemDepositLineIds = items.map(item => item.depositLineID);
                let listSum = _.sumBy(self.itemDetail, i => 
                    !itemDepositLineIds.includes(i.depositLineID) ? i.amount : 0
                );
                let itemSum = _.sumBy(items, 'amount');
                let totalSum = listSum + itemSum;
                return _.gt(totalSum, 0);
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemKey = "depositID";
                self.detailTypeName = "Receipt Line";
                self.detailTypeNamePlural = "Receipt Lines";
                self.detailKey = "depositLineID";
                self.statuses = DepositStatus.lookupItems;
                self.accountingCodes = self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES, self.order.regionID);
                self.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.detailTypeName}`,
                        disabled: function(e) {
                            if (!self.localSecurity.AllowDepositDelete) {
                                return "Access Restricted";
                            }
                            let zeroedOutItems = e.data.map(item => {
                                return { ...item, amount: 0 };})
                            if (!self.isAmountGreaterThanZero(zeroedOutItems)) return "Total sum must be greater than 0.";
                            return _.get(self, "itemDetail.length", 0) - _.get(e.data, "length") < 1 || self.readOnly;
                        }
                    }
                ];
            },

            onAddLine(e) {
                const self = this;
                if(self.readOnly || !self.showAddLine || self.lineLimitMet) return;
                if(!self.gridInstance || self.lineLimitMet) return;
                self.gridInstance.addRow();
                //if they are allowed to alter finalized recons, and alter the amount, then warn
                if (self.warnFinalRecon) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            onDepositDateChanged(e) {
                const self = this;
                self.errorMessage = "";
                self.v$.item.depositDate.$touch();
                if(!_.isNil(self.originalItem.depositDate) && _.isNil(e.value)) {
                    self.item.typeFundID = 0;
                }
                //if they set to back to the original, just bail
                if (_.isEqual(e.value, self.originalItem.depositDate)) return;
                //if they are allowed to alter finalized recons, and happen to back date an item that falls inside the thru date of a recon, then warn
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isNil(e.value) && DateTime.fromISO(e.value).diff(DateTime.fromISO(self.order.lastFinalizedReconDate), "days").days <= 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
                //if they are allowed to alter finalized recons, and happen to post date an item that falls inside the thru date of a recon, then warn
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isNil(self.originalItem.depositDate) && DateTime.fromISO(self.originalItem.depositDate).diff(DateTime.fromISO(self.order.lastFinalizedReconDate), "days").days <= 0 && DateTime.fromISO(e.value).diff(DateTime.fromISO(self.order.lastFinalizedReconDate), "days").days > 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            onDeleteDetailItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if(self.readOnly) return;
                let ok = function (args) {
                    let ids = _.map(e.data, self.detailKey);
                    let apiPromise = self.$api.CheckWritingApi.deleteDepositLines(self.item.depositID, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            let deposit = new DepositShortDto(result.deposits[0]);
                            let depositLines = _.clone(self.itemDetail);
                            _.pullAllBy(depositLines, [{depositID: self.item.depositID}], self.itemKey);
                            _.each(result.depositLines, dl => {
                                depositLines.push(new DepositLineDto(dl));
                            });

                            self.ledgerSummary = result.orderSummary;
                            self.item.amount = self.originalItem.amount = deposit.amount;
                            self.item.description = self.originalItem.description = deposit.description;
                            self.item.lineCount = self.originalItem.lineCount = deposit.lineCount;
                            self.itemDetail = self.originalDetail = depositLines;
                            self.detailUpdates = true;
                            let message = ids.length > 1 ? `${ids.length} ${self.detailTypeNamePlural} were deleted.` : `${self.detailTypeName} was deleted.`
                            self.$toast.success({ message: message });

                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.detailTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${e.data.length > 1 ? self.detailTypeNamePlural : self.detailTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onDetailGridInsert(values) {
                const self = this;
                let newItem = new DepositLineDto(values);
                let lookupInfo = self.getDepositLineLookup(newItem.lineNumberStr);
                if (lookupInfo) {
                    //just in case they altered the description
                    newItem.lineNumberStr = lookupInfo.lineNumber;
                    newItem.description = lookupInfo.lineDescription;
                }
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveDepositLine(newItem, changes)
                    .then(result => {
                        let deposit = new DepositShortDto(result.deposits[0]);
                        let depositLines = _.clone(self.itemDetail);
                        depositLines.push(new DepositLineDto(result.depositLines[0]));


                        self.ledgerSummary = result.orderSummary;
                        //only set the amount in case they haven't saved changes to the check
                        self.item.amount = self.originalItem.amount = deposit.amount;
                        self.item.lineCount = self.originalItem.lineCount = deposit.lineCount;
                        self.item.description = self.originalItem.description = deposit.description;
                        self.itemDetail = self.originalDetail = depositLines;
                        self.detailUpdates = true;
                        self.$toast.success({ message: `${self.detailTypeName} ${newItem.description || '(Blank)'} was created.` });
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.detailTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onDetailGridUpdate(key, values) {
                const self = this;
                let detailIndex = _.findIndex(self.itemDetail, [self.detailKey, key]);
                if(detailIndex < 0) return;

                let detailItem = _.cloneDeep(self.itemDetail[detailIndex]);
                let updatedItem = new DepositLineDto(_.assign({}, self.itemDetail[detailIndex], values));
                let lookupInfo = self.getDepositLineLookup(updatedItem.lineNumberStr);
                if (lookupInfo) {
                    //just in case they altered the description
                    updatedItem.lineNumberStr = lookupInfo.lineNumber;
                    updatedItem.description = lookupInfo.lineDescription;
                }
                let changes = self.getAuditChanges(detailItem.toDataObject(), updatedItem.toDataObject());

                return self.saveDepositLine(updatedItem, changes)
                    .then(result => {
                        let deposit = new DepositShortDto(result.deposits[0]);
                        let depositLines = _.clone(self.itemDetail);
                        _.assign(depositLines[detailIndex], new DepositLineDto(result.depositLines[0]));
                        //only set the amount in case they haven't saved changes to the check
                        self.ledgerSummary = result.orderSummary;
                        self.item.amount = self.originalItem.amount = deposit.amount;
                        self.item.description = deposit.description;
                        self.itemDetail = self.originalDetail = depositLines;
                        self.detailUpdates = true;
                        self.$toast.success({ message: `${self.detailTypeName} ${updatedItem.description || '(Blank)'} was saved.` });
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.detailTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh(false);
                    });
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(e.dataField === "accountingCodeID") {e.editorOptions.disabled = !this.allowEditAccountCodePermission(e.row.data); return;}
                e.editorOptions.disabled = this.readOnly;
            },

            onEditingStart(e){
                //if they are allowed to alter finalized recons, and alter the amount, then warn
                if (this.warnFinalRecon) {
                    this.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            onFundTypeBlur(e){
                this.v$.$touch();
            },

            onFundTypeChange(e){
                if(e.value !== FundOption.Wire) {
                    this.item.expectedWireDate = null;
                    this.item.wireNumber = null;
                }
            },

            onExpectedWireDateChanged(e) {
                this.item.typeFundID = _.isNullOrEmpty(this.item.wireNumber) && _.isNullOrEmpty(e.value) ? this.item.typeFundID : FundOption.Wire;
            },

            onWireNumberChanged(e) {
                this.item.typeFundID = _.isNullOrEmpty(this.item.expectedWireDate) && _.isNullOrEmpty(e.target.value) ? this.item.typeFundID : FundOption.Wire;
            },

            refresh() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
                this.$nextTick(() => {
                    this.gridInstance.updateDimensions();
                });
            },

            saveGridChanges(){
                const self = this;
                let promises = [];
                if(!_.isEmpty(self.gridInstance) && self.gridInstance.hasEditData()) promises.push(self.gridInstance.saveEditData());
                return Promise.all(promises);
            },

            save(){
                const self = this;
                self.errorMessage = "";
                self.v$.$touch();
                if(self.readOnly) return;
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields.'}); }
                let gridChanges = self.gridHasChanges();
                let changes = self.getAuditChanges(self.originalItem, self.item, ["gfNo"]);

                let depositDateChanged = _.find(changes, c => c.name == "depositDate");
                if(depositDateChanged != null && (self.hasReconciliationDate || self.disableDepositDate)) return;

                if (changes.length == 0 && !gridChanges) {
                    if (!self.detailUpdates) self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve({deposits: [self.item], depositLines: self.itemDetail, orderSummary: self.ledgerSummary});
                }
                let isEarnestMoney = _.some(self.depositLines, ["description", "Earnest Money"]);
                return self.saveGridChanges()
                    .then(() => {
                        let gridChanges = self.gridHasChanges();
                        //if there were changes to the grid that didn't save due to validation error(s), then reject
                        if (gridChanges) return Promise.reject({errorMessage: `Please correct ${self.detailTypeName} error.`});
                        if (isEarnestMoney) self.refreshOrder = true;
                        let apiPromise = self.$api.CheckWritingApi.saveDeposit(self.item, changes);
                        return self.$rqBusy.wait(apiPromise);
                    });
            },

            saveDepositLine(item, changes){
                const self = this;
                self.errorMessage = "";
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                if (!self.isUniqueLine(item)) {
                    self.errorMessage = "That Line# already exists.";
                    return Promise.reject(self.errorMessage);
                }

                let apiPromise = self.$api.CheckWritingApi.saveDepositLine(self.item.ordersID, item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise);
            }

        }
    }
</script>
<style lang="scss">
.rq-cw-modal-datagrid.grid-container {
    .dx-grid-container { height: calc(100vh - 600px) !important; }
}
</style>